/* Trulioo sdk styles */
.ooQRPanel {
  flex: 1 !important;
}

.ooQRCode .ooQRCodeImage {
  margin: 0 auto !important;
}
.ooCameraPlayer img {
  height: 100%;
}

.ooButton--large {
  font-size: 18px !important;
  line-height: 32px !important;
  padding: 12px 24px !important;
}

